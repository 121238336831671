/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function ContentPage() {
  const [mediaUrl, setMediaUrl] = useState("");
  const { folder } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMedia = async () => {
      if (mediaUrl === "") {
        console.log(localStorage.getItem("token"));
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
          return;
        }
        try {
          const endpoint = folder ? `/${folder}` : `/random`;
          const response = await axios.get(
            `https://karuna-birthday-backend.vercel.app${endpoint}`,
            {
              headers: { Authorization: token },
            }
          );
          setMediaUrl(response.data.url);
        } catch (error) {
          alert("Error fetching content. Make sure you are logged in.");
          navigate("/login");
        }
      }
    };

    fetchMedia();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    mediaUrl && (
      <div className="content-container">
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
        {mediaUrl.split("?")[0].endsWith(".mp4") ? (
          <video src={mediaUrl} controls autoPlay loop />
        ) : (
          <img src={mediaUrl} alt="Content" />
        )}
      </div>
    )
  );
}

export default ContentPage;
